<div class="coming-soon-area" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="coming-soon-content">
                <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="image"></a>
                <h2>We Are Launching Soon</h2>
                <div id="timer" class="flex-wrap d-flex justify-content-center">
                    <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}} <span>Days</span></div>
                    <div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}} <span>Hours</span></div>
                    <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}} <span>Minutes</span></div>
                    <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}} <span>Seconds</span></div>
                </div>
                <form class="newsletter-form">
                    <div class="form-group">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required>
                        <span class="label-title"><i class='bx bx-envelope'></i></span>
                    </div>
                    <button type="submit" class="default-btn"><i class="flaticon-user"></i>Subscribe<span></span></button>
                    <p>If you would like to be notified when your app is live, Please subscribe to our mailing list.</p>
                </form>
            </div>
        </div>
    </div>
</div>