<div class="boxes-area boxes-style-two bg-f5f7fa">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box-item">
                    <div class="image">
                        <img src="assets/img/boxes-img1.png" alt="image">
                    </div>
                    <h3>Web Development</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now!</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box-item">
                    <div class="image">
                        <img src="assets/img/boxes-img2.png" alt="image">
                    </div>
                    <h3>UX/UI Design</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now!</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box-item">
                    <div class="image">
                        <img src="assets/img/boxes-img3.png" alt="image">
                    </div>
                    <h3>App Development</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now!</a>
                </div>
            </div>
        </div>
        <div class="boxes-info">
            <p>If you want more? <a routerLink="/courses-1">View More Courses</a></p>
        </div>
    </div>
</div>