<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Certificazioni</li>
                <li>Inglese</li>
            </ul>
            <h2>Certificazione Inglese</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="{{urlImageHD+'inglese.jpg'}}" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Inglese">
                            <div class="courses-overview">
                                <p>
                                    è parte del piano didattico obbligatorio, ma il nostro sistema scolastico non assicura che ogni diplomato abbia effettivamente lo stesso livello di inglese.
Il divario tra i vari candidati ad un concorso, all’università o davanti al nostro futuro datore di lavoro può essere spropositato.
Il livello dell’inglese spesso è legato solo alla bravura e alla passione dei docenti che abbiamo incontrato durante il nostro percorso scolastico.
Chi parte svantaggiato, può vedersi negata l’occasione davanti a nuove opportunità e possibilità di carriera e chi ha già avuto esperienze negative con i docenti di lingua non è certamente motivato a riaffrontare un percorso di studi con l’incertezza di non raggiungere l’obiettivo.<br><br>
Con una certificazione linguistica di inglese il nostro curriculum diventa un biglietto da visita che acquista valore e spendibilità.
Una soddisfazione personale dopo un percorso di studi online che da valore al tuo tempo e al tuo futuro.
Per chi è sicuro del proprio livello e deve solo certificare le proprie competenze della lingua inglese. Un accesso diretto alla sezione d’esame più vicina, senza obbligo di seguire lezioni o pagare servizi non necessari.
Hai già seguito diversi corsi, usi quotidianamente l’inglese o hai le esperienze e conoscenze adeguate per superare l’esame con successo? Iscriviti subito all’esame e certifica il tuo inglese livello B2 o C1.<br><br>
I vari livelli di Inglese per una certificazione linguistica
Secondo il quadro comune europeo di riferimento per le lingue
Oltre al valore di immagine curriculare, una certificazione inglese B2 o C1 ha estremo valore ai fini dei concorsi con valutazione dei titoli o per graduatorie statali come le graduatorie di istituto.
Le certificazioni della lingua inglese possono essere riconosciute dagli atenei per non dover ripetere esami su competenze già acquisite.

                            </p>
                            <br>
                            <h3>Livello B2 Inglese</h3>
                            <p>
                                Autonomia – Indica che il candidato sa esprimersi con scioltezza e spontaneità. Comprende testi complessi riguardanti il proprio settore e sa produrre testi chiari e dettagliati. Sai esprimere senza difficoltà una tua opinione, parlare di un argomento o di una notizia di attualità elencando e discutendo dei pro e contro delle varie opzioni? Allora è il tuo livello. Equivale a: First Certificate, IELTS 5.5-7.00 e Business Vantage
                            </p>
                            <br>
                            <h3>Livello C1 Inglese</h3>
                            <p>
                                Avanzato – Questo livello definisce uno standard qualitativo alto, vicino al madrelingua. Chi ne è in possesso riesce a capire ed elaborare una serie di testi elaborati. Dialoga e si espone con scioltezza. Non ha limitazioni nelle tematiche dell’argomentazione e ha un controllo degli elementi della costruzione testuale molto dettagliata. Leggere e produrre testi complicati e dettagliati senza il supporto di libri e dizionari? Il C1 è a portata di mano.
Equivale a: Advanced, IELTS 7.00-8.00 E Business Advanced
                            </p>
                            <br>
                            <h3>Livello C2 Inglese</h3>
                            <p>
                                Padronanza – Questo livello definisce uno standard qualitativo madrelingua. Il livello C2 ha una conoscenza dell’inglese pari a quella di un madrelingua. con una comprensione di tutto ciò che si sente e si legge, con facilità. Il candidato sa riassumere informazioni provenienti da diverse fonti sia parlate che scritte, sa esprimersi spontaneamente, in modo molto scorrevole e preciso, individuando le sfumature di significato più sottili in situazioni complesse.
                            Equivale a: Proficiency, IELTS 8.50-9.00
                            <br><br>
                            Non sai il tuo livello di inglese? Prenota una sessione con un LiveTeacher e valuta le tue competenze.
                            </p>
                            <br>
                            <h3>LiveTeacher</h3>
                            <p>Conversa online con un docente qualificato Migliora l’inglese con sessioni telefoniche e online.
                            <br>Le Certificazioni linguistiche sono utili perché:</p>
                            <ul>
                                <li>Per fini curriculari</li>
                                <li>Per concorsi con valutazione dei titoli</li>
                                <li>Per graduatorie</li>
                                <li>Per dare valore alle proprie conoscenze e certificare le proprie qualità</li>
                            </ul>
                            </div>
                            <div class="btn-box mt-5">
                                <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 d-none">
                <div class="courses-details-info">
                    <div class="image">
                        <img src="assets/img/courses/img1.jpg" alt="image">
                        <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="link-btn popup-youtube"></a>
                        <div class="content">
                            <i class="flaticon-play"></i>
                            <span>Course Preview</span>
                        </div>
                    </div>
                    <ul class="info">
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-tag"></i> Price</span>
                                $49
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Instructor</span>
                                Sarah Taylor
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-time"></i> Duration</span>
                                7 weeks
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> Lessons</span>
                                25
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> Enrolled</span>
                                255 students
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> Access</span>
                                Lifetime
                            </div>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    <div class="courses-area bg-f8f9f8 pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Altri corsi che potrebbero piacerti</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6" *ngFor="let propostaCorso of nPropostaCorsi">
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}" class="d-block image">
                                <img src="{{urlImage+propostaCorso['corso']['image']}}" alt="image">
                            </a>
                        </div>
                        <div class="courses-content">
                            <h3><a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}">{{propostaCorso['corso']['titolo']}}</a></h3>
                            <p style="overflow: hidden;
                                      text-overflow: ellipsis;
                                      display: -webkit-box;
                                      -webkit-line-clamp: 3;
                                      -webkit-box-orient: vertical">{{propostaCorso['corso']['descrizione']}}</p>
                            <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                <li class="fw-bold"><i class='flaticon-time-left'></i>
                                    {{propostaCorso['corso']['durata'].durata_numero}}{{propostaCorso['corso']['durata'].durata_label}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
