import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-about',
  templateUrl: './vendor-about.component.html',
  styleUrls: ['./vendor-about.component.scss']
})
export class VendorAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
