<div class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Learn At Your Own Pace</span>
            <h2 class="font-weight-black">Top Selling Courses</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>
        <div class="courses-slides-two">
            <owl-carousel-o [options]="coursesSlides">
                <ng-template carouselSlide>
                    <div class="single-kindergarten-courses-box">
                        <div class="courses-image">
                            <div class="image">
                                <img src="assets/img/courses/kindergarten-img1.jpg" alt="image">
                            </div>
                            <div class="price"><img src="assets/img/price-bg.png" alt="image"><span>$39</span></div>
                            <a routerLink="/single-courses-1" class="link-btn"></a>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                <span>Alex Morgan</span>
                            </div>
                            <h3 class="font-weight-black"><a routerLink="/single-courses-1">Equivalent fractions and comparing fractions</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-kindergarten-courses-box">
                        <div class="courses-image">
                            <div class="image">
                                <img src="assets/img/courses/kindergarten-img2.jpg" alt="image">
                            </div>
                            <div class="price"><img src="assets/img/price-bg.png" alt="image"><span>$49</span></div>
                            <a routerLink="/single-courses-1" class="link-btn"></a>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                <span>Sarah Taylor</span>
                            </div>
                            <h3 class="font-weight-black"><a routerLink="/single-courses-1">Arithmetic patterns and problem solving</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-kindergarten-courses-box">
                        <div class="courses-image">
                            <div class="image">
                                <img src="assets/img/courses/kindergarten-img3.jpg" alt="image">
                            </div>
                            <div class="price"><img src="assets/img/price-bg.png" alt="image"><span>$59</span></div>
                            <a routerLink="/single-courses-1" class="link-btn"></a>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                <span>David Warner</span>
                            </div>
                            <h3 class="font-weight-black"><a routerLink="/single-courses-1">The unit circle definition of sine, and cosine</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-kindergarten-courses-box">
                        <div class="courses-image">
                            <div class="image">
                                <img src="assets/img/courses/kindergarten-img4.jpg" alt="image">
                            </div>
                            <div class="price"><img src="assets/img/price-bg.png" alt="image"><span>$69</span></div>
                            <a routerLink="/single-courses-1" class="link-btn"></a>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/user4.jpg" class="rounded-circle" alt="image">
                                <span>James Andy</span>
                            </div>
                            <h3 class="font-weight-black"><a routerLink="/single-courses-1">Thinking about multivariable functions</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="kindergarten-shape11"><img src="assets/img/kindergarten-shape/k-shape11.png" alt="image"></div>
    <div class="kindergarten-shape12"><img src="assets/img/kindergarten-shape/k-shape12.png" alt="image"></div>
</div>