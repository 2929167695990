<div class="advisor-area bg-f9f9f9 pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Instructor</span>
            <h2>Course Advisor</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="advisor-slides">
            <owl-carousel-o [options]="advisorSlides">
                <ng-template carouselSlide>
                    <div class="single-advisor-box">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <div class="advisor-image">
                                    <img src="assets/img/advisor/img1.jpg" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <div class="advisor-content">
                                    <h3><a routerLink="/profile">William James</a></h3>
                                    <span class="sub-title">Project Management Expert</span>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol aliqua.</p>
                                    <ul class="social-link">
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-advisor-box">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <div class="advisor-image">
                                    <img src="assets/img/advisor/img2.jpg" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <div class="advisor-content">
                                    <h3><a routerLink="/profile">Sarah Taylor</a></h3>
                                    <span class="sub-title">Agile Project Expert</span>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol aliqua.</p>
                                    <ul class="social-link">
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-advisor-box">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <div class="advisor-image">
                                    <img src="assets/img/advisor/img3.jpg" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <div class="advisor-content">
                                    <h3><a routerLink="/profile">James Andy</a></h3>
                                    <span class="sub-title">QA Project Expert</span>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol aliqua.</p>
                                    <ul class="social-link">
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>