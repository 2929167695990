<app-modern-schooling-banner></app-modern-schooling-banner>

<app-boxes></app-boxes>

<app-modern-schooling-about></app-modern-schooling-about>

<app-modern-schooling-courses></app-modern-schooling-courses>

<app-feedback></app-feedback>

<app-top-categories></app-top-categories>

<div class="bg-fffaf3">
    <app-funfacts-style-one></app-funfacts-style-one>
</div>

<app-overview></app-overview>

<app-modern-schooling-events></app-modern-schooling-events>

<div class="subscribe-area ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div>

<div class="partner-area bg-fe4a55 ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>