import { Component, OnInit } from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { Router, ActivatedRoute} from '@angular/router';
import { SchedaCorso } from '../../interface/scheda-corso';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

import { ViewChild } from '@angular/core';
import { TabComponent, TabsetComponent } from 'ngx-tabset';

@Component({
  selector: 'app-eipass',
  templateUrl: './eipass.html',
  styleUrls: ['./eipass.scss']
})
export class Eipass implements OnInit {
    @ViewChild('staticTabs') staticTabs: TabsetComponent;
    @ViewChild('tabs2') tabs2: TabComponent;


    public productID: any;
    public detailUser: string[];
    public moduliUC: string[];
    public nModuliUC: number;
    public ModuloUC_frequenza: number;

    public tempoTrascorso: number = 0;
    public arraySaveDatiUC_Tempo: Array<any>=[];
    public timerHandle: any;

    public nPropostaCorsi: any=[];
    public nParagrafi: number = 0;
    public elencoDataCorso: any=[];
    public detailQuiz: any=[];

    public isEnabledUser: boolean=false;
    public isInLive: number=0;
    public limita_moduloUC: number=0;
    public lockModuliUC: number=0;
    public isInLiveLinkAula: string="";
    public arrayLive: any=[];

    public detallCorso: SchedaCorso = {} as SchedaCorso;
    public urlImage = environment.urlImageCorso;
    public urlImageHD = environment.urlImageCorsoHD;
    public urlVideoCorso = environment.urlImageVideoCorso;
    public videoPreview = environment.urlImageVideoCorso;

    public briciolaTag: string;
    public cntClick: number = 1;

    constructor(
        private crudService: CrudService,
        private router: Router,
        private actRoute: ActivatedRoute) { }

   ngOnInit() {
    //mi recupero il dettaglio dell'user dopo il LOGIN
    this.detailUser=JSON.parse(localStorage.getItem('user_id'));

    //mi recupero l'ID del CORSO per ricavare la scheda
    this.productID = this.actRoute.snapshot.params['id'];

    if(this.detailUser) {
        this.urlVideoCorso+=this.detailUser['id_partner']+"/";
        this.videoPreview+=this.detailUser['id_partner']+"/";
        let idUser=this.detailUser['id_user'];
        let idCorsista=this.detailUser['id_corsista']
    }
    else {
        this.urlVideoCorso+="1/";
        this.videoPreview+="1/";
    }
   }
}
