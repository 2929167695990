<div class="teacher-register-area ptb-100">
    <div class="container">
        <div class="teacher-register-box">
            <h2>Register to Become an Intructor</h2>
            <p>Your email address will not be published. Required fields are marked *</p>
            <form>
                <div class="form-group">
                    <input type="text" placeholder="Your Name *">
                </div>
                <div class="form-group">
                    <input type="email" placeholder="Your Email *">
                </div>
                <div class="form-group">
                    <input type="text" placeholder="Your Phone *">
                </div>
                <div class="form-group">
                    <textarea placeholder="Your Message *" cols="30" rows="5"></textarea>
                </div>
                <button type="submit" class="default-btn">Submit Message<span></span></button>
            </form>
        </div>
    </div>
</div>