<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Certificazioni</li>
                <li>EIPASS</li>
            </ul>
            <h2>Certificazione EIPASS</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="assets/img/courses/course-details.jpg" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="EIPASS">
                            <div class="courses-overview">
                                <p>Qualifica e aggiungi valore al tuo Curriculum Vitae, facilitando il tuo inserimento nel mondo del lavoro.<br><br>
                                    Il programma di certificazione informatica EIPASS attesta il possesso delle competenze ICT, così come descritte negli standard internazionali alla base del nuovo curriculum Europass: come per le competenze linguistiche, anche per quelle informatiche ora è prevista una specifica tabella in cui puoi indicare il tuo livello di competenze digitali.<br><br>
                                    Tutte le certificazioni sono strutturate su standard condivisi a livello internazionale: questo è un aspetto determinante per il riconoscimento delle certificazioni informatiche nei diversi Paesi ed è inoltre di notevole utilità per le aziende in cerca di personale qualificato e per chi vuole ottenere maggiore punteggio in graduatorie e concorsi pubblici.
                                </p>
                            </div>
                            <div class="btn-box mt-5">
                                <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 d-none">
                <div class="courses-details-info">
                    <div class="image">
                        <img src="assets/img/courses/img1.jpg" alt="image">
                        <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="link-btn popup-youtube"></a>
                        <div class="content">
                            <i class="flaticon-play"></i>
                            <span>Course Preview</span>
                        </div>
                    </div>
                    <ul class="info">
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-tag"></i> Price</span>
                                $49
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Instructor</span>
                                Sarah Taylor
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-time"></i> Duration</span>
                                7 weeks
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> Lessons</span>
                                25
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> Enrolled</span>
                                255 students
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> Access</span>
                                Lifetime
                            </div>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    <div class="courses-area bg-f8f9f8 pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Altri corsi che potrebbero piacerti</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6" *ngFor="let propostaCorso of nPropostaCorsi">
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}" class="d-block image">
                                <img src="{{urlImage+propostaCorso['corso']['image']}}" alt="image">
                            </a>
                        </div>
                        <div class="courses-content">
                            <h3><a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}">{{propostaCorso['corso']['titolo']}}</a></h3>
                            <p style="overflow: hidden;
                                      text-overflow: ellipsis;
                                      display: -webkit-box;
                                      -webkit-line-clamp: 3;
                                      -webkit-box-orient: vertical">{{propostaCorso['corso']['descrizione']}}</p>
                            <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                <li class="fw-bold"><i class='flaticon-time-left'></i>
                                    {{propostaCorso['corso']['durata'].durata_numero}}{{propostaCorso['corso']['durata'].durata_label}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
