import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/components/services/crud.service';

@Component({
    selector: 'slider-carousel-home',
    templateUrl: './slider-carousel-home.component.html',
    styleUrls: ['./slider-carousel-home.component.scss']
})
export class SliderCarouselHome implements OnInit {

    public idCorsista : string="";
    public urlImage = environment.urlImageCorsoHD;
    public elencoCorsiInHome : any=[];

    constructor(
        private crudService: CrudService
    ) { }

    ngOnInit(): void {
        let detailUser=JSON.parse(localStorage.getItem('user_id'));
        if(detailUser) {
            this.idCorsista=detailUser['id_corsista']
        }

        this.loadCorsiInHome(this.idCorsista);
    }

    loadCorsiInHome(idCorsista) {
        this.crudService.api_get_ElencoCorsiInHome(idCorsista).subscribe(productData => {
            //console.log(productData);
            this.elencoCorsiInHome=productData;
        },(err)=>{
            //console.log(err);
            }, ()=>{
        });
    }

    gymBannerSlides: OwlOptions = {
        items: 1,
        nav: true,
        loop: true,
        dots: true,
        autoplay: false,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        autoplayHoverPause: true,
        navText: [
            "<i class='flaticon-chevron'></i>",
            "<i class='flaticon-right-arrow'></i>"
        ],
        responsive: {
            0: {
                autoHeight: true
            },
            576: {
                autoHeight: false
            },
            768: {
                autoHeight: false
            },
            992: {
                autoHeight: false
            }
        }
    }

    bgImage = [
        {
            img: 'assets/img/gym-banner-bg.jpg'
        }
       /*
        {
            img: 'assets/img/gray-bg-slider.jpg'

        }
        */
    ]

}
