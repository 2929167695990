import { Component, OnInit } from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile-authentication',
  templateUrl: './profile-authentication.component.html',
  styleUrls: ['./profile-authentication.component.scss']
})
export class ProfileAuthenticationComponent implements OnInit {

  constructor(
    private crudService: CrudService,
  ) { }

  ngOnInit(): void {
  }

  resetPassword() {
    Swal.fire({
        icon: 'info',
        title: 'Sei sicuro?',
        text: 'vuoi procedere al reset della password?',
        showCancelButton: true,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Si, confermo!',
        cancelButtonText: 'Annulla',
        width: '400px',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
            let emailReset=(document.getElementById('emailReset')  as HTMLInputElement).value;
            let username=(document.getElementById('usernameReset')  as HTMLInputElement).value;

            if(emailReset) {
                this.crudService.api_modificaPasswordEmail(username, emailReset).subscribe(
                    productData => {
                        console.log(productData);

                        if(productData!=0) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Reset Password',
                                text: 'password resettata con successo. La nuova password è "'+username+'", per modificare la password, accedi al tuo profilo e modifica la password.',
                                showCancelButton: false,
                                confirmButtonColor: '#6993FF',
                                confirmButtonText: 'Chiudi',
                                width: '400px',
                                allowOutsideClick: false,
                              }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.reload();
                                }
                              })
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Errore!',
                                text: 'non riesco a cambiare la password, contatta l\'assistenza.',
                                showCancelButton: false,
                                confirmButtonColor: '#6993FF',
                                confirmButtonText: 'Riprova',
                                width: '400px',
                                allowOutsideClick: false,
                              }).then((result) => {
                                if (result.isConfirmed) {
                                }
                              })
                        }
                    },(err)=>{
                        console.log(err);
                        }, ()=>{
                    }
                );
            }
            else  {
                Swal.fire({
                    icon: 'error',
                    title: 'Errore!',
                    text: 'compila i campi USERNAME e E-MAIL.',
                    showCancelButton: false,
                    confirmButtonColor: '#6993FF',
                    confirmButtonText: 'Riprova',
                    width: '400px',
                    allowOutsideClick: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                    }
                  })
            }
        }
    })
  }

}
