<div class="free-trial-area ptb-100 bg-fffaf3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="free-trial-image text-center">
                    <img src="assets/img/free-trial-img.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="free-trial-form">
                    <span class="sub-title">Free Trial</span>
                    <h2>Sign Up For A Free Trial</h2>
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Name *">
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Your Email *">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Phone *">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Subject *">
                        </div>
                        <button type="submit">Register Now</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>