import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features-style-two',
  templateUrl: './features-style-two.component.html',
  styleUrls: ['./features-style-two.component.scss']
})
export class FeaturesStyleTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
