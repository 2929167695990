<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="kindergarten-about-image">
                    <div class="main-image">
                        <img src="assets/img/kindergarten-about-img1.png" alt="image">
                        <img src="assets/img/kindergarten-about-img2.png" alt="image">
                    </div>
                    <div class="shape"><img src="assets/img/kite1.png" alt="image"></div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2 class="font-weight-black">We are Kindergarten & Childhood is our Passion</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <ul class="about-list">
                        <li><span><i class='bx bx-check'></i> Transportation</span></li>
                        <li><span><i class='bx bx-check'></i> Outdoor Games</span></li>
                        <li><span><i class='bx bx-check'></i> Nutritious Food</span></li>
                        <li><span><i class='bx bx-check'></i> Best Care</span></li>
                    </ul>
                    <a routerLink="/about-5" class="default-btn-style-two"><i class="flaticon-user"></i>More About Us</a>
                </div>
            </div>
        </div>
    </div>
    <div class="kindergarten-shape7"><img src="assets/img/kindergarten-shape/k-shape7.png" alt="image"></div>
    <div class="kindergarten-shape8"><img src="assets/img/kindergarten-shape/k-shape8.png" alt="image"></div>
</div>