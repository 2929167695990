<div class="banner-wrapper" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-wrapper-text">
                    <h1>Build Skills With Experts Any Time, Anywhere</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/courses-4" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="banner-shape11"><img src="assets/img/banner-shape12.png" alt="image"></div>
    <div class="banner-shape12"><img src="assets/img/banner-shape13.png" alt="image"></div>
    <div class="banner-shape13"><img src="assets/img/banner-shape14.png" alt="image"></div>
</div>