import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscribe-style-two',
  templateUrl: './subscribe-style-two.component.html',
  styleUrls: ['./subscribe-style-two.component.scss']
})
export class SubscribeStyleTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
