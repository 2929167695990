import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-style-three',
  templateUrl: './about-style-three.component.html',
  styleUrls: ['./about-style-three.component.scss']
})
export class AboutStyleThreeComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}