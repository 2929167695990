<div class="container">
    <div class="section-title">
        <span class="sub-title">Education for everyone</span>
        <h2>Online Coaching Lessons For Remote Learning</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-features-box without-padding">
                <div class="icon">
                    <i class="flaticon-brain-process"></i>
                </div>
                <h3>Learn the Latest Skills</h3>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-features-box without-padding">
                <div class="icon">
                    <i class="flaticon-computer"></i>
                </div>
                <h3>Go at Your Own Pace</h3>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-features-box without-padding">
                <div class="icon">
                    <i class="flaticon-shield-1"></i>
                </div>
                <h3>Learn from Industry Experts</h3>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
            </div>
        </div>
    </div>
</div>