<app-vendor-banner></app-vendor-banner>

<div class="partner-area ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>

<app-slogan></app-slogan>

<app-vendor-courses></app-vendor-courses>

<app-vendor-about></app-vendor-about>

<app-funfacts-style-one></app-funfacts-style-one>

<app-vendor-popular-courses></app-vendor-popular-courses>

<app-instructor></app-instructor>

<app-premium-access-two></app-premium-access-two>

<div class="testimonials-area ptb-100">
    <app-testimonials></app-testimonials>
</div>

<app-view-all-courses-two></app-view-all-courses-two>

<div class="blog-area ptb-100">
    <app-blog></app-blog>
</div>

<div class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div>