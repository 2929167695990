<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Certificazioni</li>
                <li>Coding</li>
            </ul>
            <h2>Certificazione Coding</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="{{urlImageHD+'coding.jpg'}}" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Coding">
                            <div class="courses-overview">
                                <p>
                                    Il Coding è uno strumento che può essere utilizzato come metodo didattico per discipline di qualsiasi tipo, sia scientifiche che umanistiche: si basa sul problem solving e ha l’obiettivo di stimolare lo sviluppo del cosiddetto pensiero computazionale. L’informatica deve essere visto come uno strumento per l’implementazione e il miglioramento della didattica, attraverso l’utilizzo di linguaggi di programmazione adatti alla didattica scolastica. Il presente corso è erogato da un ente riconosciuto dal MIUR per la formazione del personale della scuola (Direttiva 170/2016) e rilascia 0.5 punti in graduatoria Docenti.
                                </p>
                                <br>
                                <h3>Modalità di svolgimento del Corso Coding per Docenti</h3>
                                <p>
                                    Il Corso Coding per Docenti è svolto interamente online per la durata totale di 150 ore.<br><br>
                                    Ogni modulo contiene un test per mettere alla prova le conoscenze acquisite e tutto il percorso si conclude con un test finale, al quale si accede direttamente al termine della parte didattica. La certificazione finale verrà generata in automatico al superamento del test finale.<br><br>
                                    Il <b>Corso Coding</b> Online per Docenti è finalizzato all’apprendimento dei linguaggi di programmazione e loro istruzioni ordinate che, eseguite passo passo, portano alla soluzione di un problema ed all’ottenimento di un obiettivo.
                                </p>
                                <br>
                                <h3>Programma del Corso</h3>
                                <ul>
                                    <li>Introduzione al Corso di Coding</li>
                                    <li>Introduzione al Coding</li>
                                    <li>Guida al Pensiero Computazionale</li>
                                    <li>Smart Coding</li>
                                    <li>Dal Pensiero Computazionale al Coding</li>
                                    <li>TEST</li>
                                    <li>Testo legge 107</li>
                                    <li>La Buona Scuola</li>
                                    <li>Sistema educativo di istruzione e formazione</li>
                                    <li>Innovazione Digitale</li>
                                    <li>La Didattica</li>
                                    <li>La Didattica Disciplinare</li>
                                    <li>Le Strategie Didattiche</li>
                                    <li>Approccio Metodologico</li>
                                    <li>Gli strumenti di analisi</li>
                                    <li>Prove Comuni</li>
                                    <li>Apprendimento Significativo e Ambienti dell’Apprendimento</li>
                                    <li>Psicologia età evolutiva</li>
                                    <li>La Psicologia dello Sviluppo</li>
                                    <li>Gli oggetti</li>
                                    <li>La mediateca</li>
                                    <li>Il Learning Object</li>
                                    <li>Lo Scorm</li>
                                    <li>CODING</li>
                                    <li>TEST</li>
                            </ul>
                            </div>
                            <div class="btn-box mt-5">
                                <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 d-none">
                <div class="courses-details-info">
                    <div class="image">
                        <img src="assets/img/courses/img1.jpg" alt="image">
                        <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="link-btn popup-youtube"></a>
                        <div class="content">
                            <i class="flaticon-play"></i>
                            <span>Course Preview</span>
                        </div>
                    </div>
                    <ul class="info">
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-tag"></i> Price</span>
                                $49
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Instructor</span>
                                Sarah Taylor
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-time"></i> Duration</span>
                                7 weeks
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> Lessons</span>
                                25
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> Enrolled</span>
                                255 students
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> Access</span>
                                Lifetime
                            </div>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    <div class="courses-area bg-f8f9f8 pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Altri corsi che potrebbero piacerti</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6" *ngFor="let propostaCorso of nPropostaCorsi">
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}" class="d-block image">
                                <img src="{{urlImage+propostaCorso['corso']['image']}}" alt="image">
                            </a>
                        </div>
                        <div class="courses-content">
                            <h3><a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}">{{propostaCorso['corso']['titolo']}}</a></h3>
                            <p style="overflow: hidden;
                                      text-overflow: ellipsis;
                                      display: -webkit-box;
                                      -webkit-line-clamp: 3;
                                      -webkit-box-orient: vertical">{{propostaCorso['corso']['descrizione']}}</p>
                            <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                <li class="fw-bold"><i class='flaticon-time-left'></i>
                                    {{propostaCorso['corso']['durata'].durata_numero}}{{propostaCorso['corso']['durata'].durata_label}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
