<div class="feedback-area bg-6dbbbd pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2 class="font-weight-black">Our Guardian Feedback</h2>
            <p>We always give extra care to our student's skills improvements and feel excited to share our latest research and learnings!</p>
        </div>
    </div>
    <div class="container-fluid">
        <div class="feedback-slides-three">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-kindergarten-feedback-item">
                        <div class="content">
                            <img src="assets/img/feedback-shape.png" alt="image">
                            <p>Lorem ipsum dolor sit aadamet45, consectetur adipiscing elit elit3, sed do eiusmod tempor incididunt ut labore et dolore magna. Eiusmod incididunt.</p>
                        </div>
                        <div class="client-info">
                            <img src="assets/img/user1.jpg" alt="image">
                            <h3 class="font-weight-black">John Smith</h3>
                            <span>Guardian</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-kindergarten-feedback-item">
                        <div class="content">
                            <img src="assets/img/feedback-shape.png" alt="image">
                            <p>Lorem ipsum dolor sit aadamet45, consectetur adipiscing elit elit3, sed do eiusmod tempor incididunt ut labore et dolore magna. Eiusmod incididunt.</p>
                        </div>
                        <div class="client-info">
                            <img src="assets/img/user2.jpg" alt="image">
                            <h3 class="font-weight-black">Sarah Taylor</h3>
                            <span>Guardian</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-kindergarten-feedback-item">
                        <div class="content">
                            <img src="assets/img/feedback-shape.png" alt="image">
                            <p>Lorem ipsum dolor sit aadamet45, consectetur adipiscing elit elit3, sed do eiusmod tempor incididunt ut labore et dolore magna. Eiusmod incididunt.</p>
                        </div>
                        <div class="client-info">
                            <img src="assets/img/user3.jpg" alt="image">
                            <h3 class="font-weight-black">David Smith</h3>
                            <span>Guardian</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-kindergarten-feedback-item">
                        <div class="content">
                            <img src="assets/img/feedback-shape.png" alt="image">
                            <p>Lorem ipsum dolor sit aadamet45, consectetur adipiscing elit elit3, sed do eiusmod tempor incididunt ut labore et dolore magna. Eiusmod incididunt.</p>
                        </div>
                        <div class="client-info">
                            <img src="assets/img/user4.jpg" alt="image">
                            <h3 class="font-weight-black">James Andy</h3>
                            <span>Guardian</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-kindergarten-feedback-item">
                        <div class="content">
                            <img src="assets/img/feedback-shape.png" alt="image">
                            <p>Lorem ipsum dolor sit aadamet45, consectetur adipiscing elit elit3, sed do eiusmod tempor incididunt ut labore et dolore magna. Eiusmod incididunt.</p>
                        </div>
                        <div class="client-info">
                            <img src="assets/img/user5.jpg" alt="image">
                            <h3 class="font-weight-black">Max Lucy</h3>
                            <span>Guardian</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-kindergarten-feedback-item">
                        <div class="content">
                            <img src="assets/img/feedback-shape.png" alt="image">
                            <p>Lorem ipsum dolor sit aadamet45, consectetur adipiscing elit elit3, sed do eiusmod tempor incididunt ut labore et dolore magna. Eiusmod incididunt.</p>
                        </div>
                        <div class="client-info">
                            <img src="assets/img/user6.jpg" alt="image">
                            <h3 class="font-weight-black">Harry Zayn</h3>
                            <span>Guardian</span>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="kindergarten-shape13"><img src="assets/img/kindergarten-shape/k-shape13.png" alt="image"></div>
    <div class="kindergarten-shape14"><img src="assets/img/kindergarten-shape/k-shape14.png" alt="image"></div>
</div>