<div class="gym-feedback-area ptb-100">
    <div class="container-fluid">
        <div class="gym-feedback-slides">
            <owl-carousel-o [options]="gymFeedbackSlides">
                <ng-template carouselSlide>
                    <div class="gym-feedback-box">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <div class="title">
                            <h3>John Smith</h3>
                            <span>Python Developer</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="gym-feedback-box">
                        <p>Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <div class="title">
                            <h3>Sarah Taylor</h3>
                            <span>Web Developer</span>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>