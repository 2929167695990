import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-quiz',
  templateUrl: './profile-quiz.component.html',
  styleUrls: ['./profile-quiz.component.scss']
})
export class ProfileQuizComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
