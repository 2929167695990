<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInLeft">
                                <img src="assets/img/about-img1.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInDown">
                                <img src="assets/img/about-img2.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInUp">
                                <img src="assets/img/about-img3.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInRight">
                                <img src="assets/img/about-img4.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Distance learning</span>
                    <h2>89% of people learning for professional development report career benefits like getting a promotion, starting a new career</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Expert Instruction</span></li>
                        <li><span><i class="flaticon-time-left"></i> Lifetime Acces</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Remote Learning</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Self Development</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
</div>

<app-funfacts-feedback></app-funfacts-feedback>

<div class="features-area pb-70">
    <app-features-style-one></app-features-style-one>
</div>

<app-instructor></app-instructor>

<div class="premium-access-area bg-f9f9f9 ptb-100">
    <app-premium-access></app-premium-access>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div>

<div class="partner-area ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>