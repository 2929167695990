<div class="premium-access-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="premium-access-content">
            <span class="sub-title">Go at your own pace</span>
            <h2>Give their limitless potential unlimited access</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <a routerLink="/membership-levels" class="default-btn"><i class="flaticon-user"></i>Give Premium Access<span></span></a>
        </div>
    </div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div>