<div class="funfacts-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                    <h3><span [countUp]="1926">00</span></h3>
                    <p>Finished Sessions</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                    <h3><span [countUp]="3279">00</span></h3>
                    <p>Enrolled Learners</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                    <h3><span [countUp]="250">00</span></h3>
                    <p>Online Instructors</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                    <h3><span [countUp]="100">00</span>%</h3>
                    <p>Satisfaction Rate</p>
                </div>
            </div>
        </div>
    </div>
</div>