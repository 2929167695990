<div class="training-area bg-f5f7fa pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="playfair-display-font">Why Online Yoga Training</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-training-box">
                    <img src="assets/img/training-img1.png" alt="image">
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Flexibility</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-training-box">
                    <img src="assets/img/training-img2.png" alt="image">
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Comfort</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-training-box">
                    <img src="assets/img/training-img3.png" alt="image">
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Resources</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now</a>
                </div>
            </div>
        </div>
    </div>
    <div class="tree-shape2"><img src="assets/img/tree-shape2.png" alt="image"></div>
    <div class="tree-shape3"><img src="assets/img/tree-shape3.png" alt="image"></div>
</div>