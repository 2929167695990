<div class="selected-ages-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go At Your Own Pace</span>
            <h2 class="font-weight-black">Ages We Meet Kids Where They Are</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-selected-ages-box">
                    <div class="image">
                        <img src="assets/img/selected-ages/img1.png" alt="image">
                    </div>
                    <div class="content">
                        <h3>Infants</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <span class="ages-number">3 - 12 Months</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-selected-ages-box">
                    <div class="image">
                        <img src="assets/img/selected-ages/img2.png" alt="image">
                    </div>
                    <div class="content">
                        <h3>Toddler</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <span class="ages-number">1 - 3 Years</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-selected-ages-box">
                    <div class="image">
                        <img src="assets/img/selected-ages/img3.png" alt="image">
                    </div>
                    <div class="content">
                        <h3>Preschool</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <span class="ages-number">3 - 5 Years</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-selected-ages-box">
                    <div class="image">
                        <img src="assets/img/selected-ages/img4.png" alt="image">
                    </div>
                    <div class="content">
                        <h3>Flex-Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <span class="ages-number">5 - 12 Years</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="kindergarten-shape15"><img src="assets/img/kindergarten-shape/k-shape15.png" alt="image"></div>
    <div class="kindergarten-shape16"><img src="assets/img/kindergarten-shape/k-shape16.png" alt="image"></div>
</div>