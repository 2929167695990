import { Component, OnInit } from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { Router, ActivatedRoute} from '@angular/router';
import { SchedaCorso } from '../../interface/scheda-corso';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-personale-ata',
  templateUrl: './personale-ata.html',
  styleUrls: ['./personale-ata.scss']
})
export class PersonaleATA implements OnInit {

    public urlImageHD = environment.urlImageCorsoHD;
    public idCorsista : string="";

    constructor(
        private crudService: CrudService,
        private router: Router,
        private actRoute: ActivatedRoute) { }

   ngOnInit() {
    let detailUser=JSON.parse(localStorage.getItem('user_id'));
    if(detailUser) {
        this.idCorsista=detailUser['id_corsista']
    }
  }

}
