<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Certificazioni</li>
                <li>Tablet</li>
            </ul>
            <h2>Certificazione Tablet</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="{{urlImageHD+'tablet.jpg'}}" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Tablet">
                            <div class="courses-overview">
                                <p>
                                    Il corso tablet si propone di sviluppare specifiche competenze su utilizzi ed applicazioni del Tablet per approfondire gli aspetti teorici, pedagogici e comunicativi coinvolti nel suo utilizzo, promuovendo l’uso del Tablet nell’ambito della normale pratica didattica.<br><br>
                                    Il Tablet è uno strumento utile che deve incoraggiare l’insegnante ad utilizzare la tecnologia a favore dell’apprendimento e per ridisegnare gli stili di insegnamento. L’uso del Tablet inoltre può essere di grande aiuto in alunni con bisogni educativi speciali.<br><br>
                                </p>
                                <h3>Destinatari del corso Tablet</h3>
                                <p>
                                    Docenti ed aspiranti docenti di scuola di ogni ordine e grado, educatori, pedagogisti, logopedisti e professionisti del settore.
                                </p>
                                <br>
                                <h3>Contenuti e moduli del corso Tablet</h3>
                                <p>
                                    Il presente corso, è caratterizzato dalla presenza di 6 Moduli:
                                </p>
                                <ul>
                                    <li>Utilizzo di strumenti per la produttività dei fini didattici</li>
                                    <li>Interface e strumenti: utilizzo di Tab Android</li>
                                    <li>Interface e strumenti: utilizzo di un iPad</li>
                                    <li>Application nella didattica: comprenderle e utilizzarle in classe</li>
                                    <li>Inserimento delle risorse digitali online: modelli di didattica collaborativa</li>
                                    <li>Insegnare in modo attivo: aspetti sistematici, setting, pianificazione e concretizzazione dell’unità di apprendimento.</li>
                                    <li>Strumenti didattici del corso Tablet</li>
                                </ul>
                                <p>
                                    Una volta perfezionata l’iscrizione, il corsista riceve le credenziali necessarie per accedere alla piattaforma telematica ed usufruire, senza limitazioni, di tutti gli appositi strumenti didattici elaborati:
                                    <b>Lezioni video on-line; </b>
                                    <b>Dispense Scaricabili; </b>
                                    <b>Test di autovalutazione;</b>
                                </p>
                                <br>
                                <h3>Modalità d’esame del corso Tablet</h3>
                                <p>
                                    L’esame finale consiste in un test risposta multipla di 60 domande da completare in 30 minuti. Nel caso di mancato superamento, il test si può ripetere altre volte.
La percentuale minima, di superamento per ogni modulo è dell’70%. Per accedere all’esame è indispensabile visionare ogni singola video lezione almeno due volte e svolgere tutte le varie esercitazioni presenti in piattaforma.
                                </p>
                            </div>
                            <div class="btn-box mt-5">
                                <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 d-none">
                <div class="courses-details-info">
                    <div class="image">
                        <img src="assets/img/courses/img1.jpg" alt="image">
                        <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="link-btn popup-youtube"></a>
                        <div class="content">
                            <i class="flaticon-play"></i>
                            <span>Course Preview</span>
                        </div>
                    </div>
                    <ul class="info">
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-tag"></i> Price</span>
                                $49
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Instructor</span>
                                Sarah Taylor
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-time"></i> Duration</span>
                                7 weeks
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> Lessons</span>
                                25
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> Enrolled</span>
                                255 students
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> Access</span>
                                Lifetime
                            </div>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    <div class="courses-area bg-f8f9f8 pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Altri corsi che potrebbero piacerti</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6" *ngFor="let propostaCorso of nPropostaCorsi">
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}" class="d-block image">
                                <img src="{{urlImage+propostaCorso['corso']['image']}}" alt="image">
                            </a>
                        </div>
                        <div class="courses-content">
                            <h3><a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}">{{propostaCorso['corso']['titolo']}}</a></h3>
                            <p style="overflow: hidden;
                                      text-overflow: ellipsis;
                                      display: -webkit-box;
                                      -webkit-line-clamp: 3;
                                      -webkit-box-orient: vertical">{{propostaCorso['corso']['descrizione']}}</p>
                            <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                <li class="fw-bold"><i class='flaticon-time-left'></i>
                                    {{propostaCorso['corso']['durata'].durata_numero}}{{propostaCorso['corso']['durata'].durata_label}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
