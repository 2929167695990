import { Component, OnInit, ViewChild } from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    @ViewChild('contactForm') contactForm:NgForm;
    public elencoCorsi: any=[];

    constructor(
        private crudService: CrudService,
    ) { }

    ngOnInit(): void {
        this.loadCorsi('');
    }

    bgImage = [
        {
            img: 'assets/img/register-shape_1.jpg'
        }
    ]

    loadCorsi(pag){
        var findCorsista: string;
        this.crudService.api_get_ElencoCorsi(pag, findCorsista, '', '', '').subscribe(productData => {
                this.elencoCorsi=productData;
            },(err)=>{
                //console.log(err);
                }, ()=>{
            }
        );
    }

    submit(form){
        var name = form.name;
        var email = form.email;
        var number = form.number;
        var message = form.message;
        var sceltaCorso = form.sceltaCorso;

        if(name && email && number && message) {
            this.crudService.sent_Email(name, email, number, message, sceltaCorso).subscribe(responseData => {
                //console.log(responseData);
                this.contactForm.reset();
                this.emailSuccess();

                },(err)=>{
                    //console.log("Errore:"+err);
                    }, ()=>{
                }
            );
        }
        else {
            this.errorForm();
        }
    }

    errorForm() {
        Swal.fire({
            title:'Campi Obbligatori!',
            text: 'bisogna compilare tutti i campi richiesti',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Grazie',
            allowOutsideClick: false,
         }).then((result) => {
            if (result.isConfirmed) {}
        });
    }

    emailSuccess() {
        Swal.fire({
            title:'Richiesta Inviata!',
            text: 'la tua richiesta è stat inviata con successo, sarai contattato dal nostro team il prima possibile',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Grazie',
            allowOutsideClick: false,
         }).then((result) => {
            if (result.isConfirmed) {}
        });
    }

}
