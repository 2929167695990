<div style="width: 100%; height: 100%; position: fixed; z-index: 999; top: 0px;" id="jitsi-iframe">
    <div *ngIf="isLoaded" class="text-center"
        style="min-width: 60px; min-height: 38px; position: absolute; left: 28px; top: 45px; border-radius: 5px;
               padding-top:6px; padding-bottom: 6px; color: white; cursor: pointer; display: none;" aria-hidden="true" title="Leave">
        <img width="85px" src="assets/img/logo_meet.png">
    </div>
<div *ngIf="isLoaded" style="background-color: var(--bs-danger); height: 40px; padding-top: 4px;
                             text-align: center; color:white; font-weight: 800; font-size: 14pt; cursor: pointer;">
    <span style="display: none!important;">Ritorna al corso / Chiudi l'aula virtuale</span>

    <img src="assets/img/logo_meet_esteso.png" style="height: 32px; border-radius: 3px; margin-left: 2px; margin-right: 8px;" (click)="executeCommand('hangup')">

     <img width="32px" src="assets/img/layout.png" style="background-color: #000; padding: 6px; border-radius: 3px; margin-left: 2px; margin-right: 2px;" (click)="executeCommand('toggleTileView')">

     <img *ngIf="!isHandMuted && !isOnlyMember" width="32px" src="assets/img/meet_hand.png" style="background-color: #000; padding: 6px; border-radius: 3px; margin-left: 2px; margin-right: 2px;" (click)="executeCommand('toggleRaiseHand')">
     <img *ngIf="isHandMuted && !isOnlyMember" width="32px" src="assets/img/meet_hand_disabled.png" style="background-color: #000; padding: 6px; border-radius: 3px; margin-left: 2px; margin-right: 2px;" (click)="executeCommand('toggleRaiseHand')">

     <img *ngIf="!isAudioMuted && !isOnlyMember" width="32px" src="assets/img/meet_mic.png" style="background-color: #000; padding: 6px; border-radius: 3px; margin-left: 2px; margin-right: 2px;" (click)="executeCommand('toggleAudio')" title="Attiva microfono">
     <img *ngIf="isAudioMuted && !isOnlyMember" width="32px" src="assets/img/meet_mic_disabled.png" style="background-color: #000; padding: 6px; border-radius: 3px; margin-left: 2px; margin-right: 2px;" (click)="executeCommand('toggleAudio')" title="Disattiva microfono">

     <img [class]="disabledVideocamera==0 ? 'd-none' : ''" *ngIf="(!isVideoMuted && !isOnlyMember)" width="32px" src="assets/img/meet_camera.png" style="background-color: #000; padding: 6px; border-radius: 3px; margin-left: 2px; margin-right: 2px;" (click)="executeCommand('toggleVideo')" title="Attiva videocamera">
     <img [class]="disabledVideocamera==0 ? 'd-none' : ''" *ngIf="(isVideoMuted && !isOnlyMember)" width="32px" src="assets/img/meet_camera_disabled.png" style="background-color: #000; padding: 6px; border-radius: 3px; margin-left: 2px; margin-right: 2px;" (click)="executeCommand('toggleVideo')" title="Disattiva videocamera">

     <img *ngIf="(!isVideoShared && !isOnlyMember && canShowExtraControls==1)" width="32px" src="assets/img/meet_screen.png" style="background-color: #000; padding: 6px; border-radius: 3px; margin-left: 2px; margin-right: 2px;" (click)="executeCommand('toggleShareScreen')" title="Condividi schermo">
     <img *ngIf="(isVideoShared && !isOnlyMember && canShowExtraControls==1)" width="32px" src="assets/img/meet_screen.png" style="background-color: #000; padding: 6px; border-radius: 3px; margin-left: 2px; margin-right: 2px;" (click)="executeCommand('toggleShareScreen')" title="Chiudi condivisione schermo">

     <img *ngIf="(!isChat && !isOnlyMember && canShowExtraControls==1)" width="32px" src="assets/img/meet_chat.png" style="background-color: #000; padding: 6px; border-radius: 3px; margin-left: 2px; margin-right: 2px;" (click)="executeCommand('toggleChat')" title="Apri chat">
     <img *ngIf="(isChat && !isOnlyMember && canShowExtraControls==1)" width="32px" src="assets/img/meet_chat.png" style="background-color: #000; padding: 6px; border-radius: 3px; margin-left: 2px; margin-right: 2px;" (click)="executeCommand('toggleChat')" title="Chiudi chat">

     <img width="32px" src="assets/img/meet_hangup.png" style="background-color: #000; padding: 6px; border-radius: 3px; margin-left: 2px; margin-right: 2px;" (click)="executeCommand('hangup')" title="Esci dall'aula">
</div>


</div>

<div class="item-center">
    <span>Custom Controls</span>
</div>
<div class="item-center">
    <span>&nbsp;&nbsp;</span>
    <i (click)="executeCommand('toggleAudio')" class="fas fa-2x grey-color" [ngClass]="isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'" aria-hidden="true" title="Mute / Unmute"></i>
    <i (click)="executeCommand('hangup')" class="fas fa-phone-slash fa-2x red-color" aria-hidden="true" title="Leave"></i>
    <i (click)="executeCommand('toggleVideo')" class="fas fa-2x grey-color" [ngClass]="isVideoMuted ? 'fa-video-slash' : 'fa-video'" aria-hidden="true" title="Start / Stop camera"></i>
    <i (click)="executeCommand('toggleShareScreen')" class="fas fa-film fa-2x grey-color" aria-hidden="true" title="Share your screen"></i>
</div>
