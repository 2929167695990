<div class="lifestyle-area bg-f6f7fb pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="playfair-display-font">Inspring you to live a healthier lifestyle</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-lifestyle-box">
                    <div class="icon">
                        <i class="flaticon-diet"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Nutrition Strategies</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-lifestyle-box">
                    <div class="icon">
                        <i class="flaticon-vitamin-c"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Personal Program</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-lifestyle-box">
                    <div class="icon">
                        <i class="flaticon-heart-rate-monitor"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Find Your Balance</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now</a>
                </div>
            </div>
        </div>
    </div>
</div>