import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products-list-style-one',
  templateUrl: './products-list-style-one.component.html',
  styleUrls: ['./products-list-style-one.component.scss']
})
export class ProductsListStyleOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
