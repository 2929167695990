import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/components/services/crud.service';

@Component({
    selector: 'app-elearning-school',
    templateUrl: './elearning-school.component.html',
    styleUrls: ['./elearning-school.component.scss']
})
export class ElearningSchoolComponent implements OnInit {

    public homeBannerSlider: boolean=false;

    constructor(
        private crudService: CrudService
    ) { }

    ngOnInit(): void {
        this.loadSettings();
    }

    loadSettings() {
        this.crudService.api_get_SettingsValue("home-banner-slider").subscribe(productData => {
            this.homeBannerSlider=productData==1 ? true : false;
        },(err)=>{
            //console.log(err);
            }, ()=>{
        });
    }

}
