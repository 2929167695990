<div class="get-instant-courses-area-two bg-f9fbff">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="get-instant-courses-content-style-two">
                    <span class="sub-title">GET INSTANT ACCESS TO THE FREE</span>
                    <h2>Self Development Course</h2>
                    <p>With The Open University you can study whenever and wherever you choose. We have students in over 128 countries, and a global reputation as a pioneer in the field of flexible learning. Our flexible teaching also means, if you travel often or need to relocate, you can continue to study wherever you go.</p>
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Start For Free<span></span></a>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="get-instant-courses-image-style-two">
                    <img src="assets/img/setting.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="bulb"><img src="assets/img/bulb2.png" alt="image"></div>
</div>