<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our News</span>
            <h2 class="font-weight-black">Check Out Our Latest Blog</h2>
            <p>We always give extra care to our student's skills improvements and feel excited to share our latest research and learnings!</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-blog-item">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="assets/img/blog/kindergarten-img1.jpg" alt="image">
                        </a>
                        <a routerLink="/single-blog-1" class="link-btn"></a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Preschool</a>
                        <h3 class="font-weight-black"><a routerLink="/single-blog-1">Why Play Is Important in Preschool and Early</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-blog-item">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="assets/img/blog/kindergarten-img2.jpg" alt="image">
                        </a>
                        <a routerLink="/single-blog-1" class="link-btn"></a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Books</a>
                        <h3 class="font-weight-black"><a routerLink="/single-blog-1">Best Three Billy Goats Gruff Books for Preschool</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-blog-item">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="assets/img/blog/kindergarten-img3.jpg" alt="image">
                        </a>
                        <a routerLink="/single-blog-1" class="link-btn"></a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Theme</a>
                        <h3 class="font-weight-black"><a routerLink="/single-blog-1">Flashlight Literacy Activity for Your Camping Theme</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>