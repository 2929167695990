<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Validità Attestato</li>
            </ul>
            <h2>Validità Attestato</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <div class="courses-details-image">
        <img src="assets/img/validita-attestato.jpg"  style="max-height:550px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
</div>

<div *ngIf="!showCorsista" class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Come controllare la validità dell'attestato?</h2>
                    <p>
                        Tutti gli attestati rilasciati sono presenti all’interno di un database al fine di verificare la validità degli stessi.<br><br>
                        Per cercare un certificato si deve inserire, necessariamente, il nominativo del corsista/discente, la data di nascita e codice fiscale.<br><br>
                        Una volta verificato il tuo attestato, con esito positivo, ti sarà comunicato il tuo codice di protocollo univoco e data di rilascio dell'attestato
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Verifica la validità dall'attestato</h2>
                    <p>compila i campi richiesti obbligatori per verificare la validità dell'attestato, alla fine clicca sul pulsante "Vertiica Attestato"</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="cognome" type="text" #name="ngModel" class="form-control" id="cognome" placeholder="Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="data_nascita" type="date" #email="ngModel" class="form-control pe-3" id="data_nascita" placeholder="data di nascita">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">data di nascita obbligatoria</div>
                                    <span class="text-white" style="font-size: 10pt">inserisci la tua data di nascita</span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="cf" type="text" #number="ngModel" class="form-control" id="cf" placeholder="codice fiscale">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">il codice fiscale è obbligatorio</div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Verifica Attestato<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showCorsista" class="cart-area ptb-100">
    <div class="container">
        <div class="contact-info">
            <span class="sub-title">ELENCO CORSI</span>
            <h2>{{arrayAttestati[0]['corsista']}}</h2>
            <p>
                elenco dettagliato dei certificati ottenuti dal corsista con i riferimenti al protocollo e data di validità attetstato
            </p>
        </div>
            <div class="cart-table table-responsive pt-5">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Corso</th>
                            <th scope="col" class="pe-3">Durata</th>
                            <th scope="col">Protocollo</th>
                            <th scope="col">Data Attestato</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let rowAttestati of arrayAttestati; index as indexAttestati;">
                            <td class="product-thumbnail">
                                <span>{{rowAttestati['titolo']}}</span>
                            </td>
                            <td class="product-name">
                                <span>{{rowAttestati['durata']}}h</span>
                            </td>
                            <td class="product-price">
                                <span class="fw-bolder">{{rowAttestati['protocollo']}}</span>
                            </td>
                            <td class="product-quantity">
                                <span class="">{{rowAttestati['dataAttestatoFormattato']}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </div>
</div>
