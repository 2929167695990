<app-distance-banner></app-distance-banner>

<app-distance-boxes></app-distance-boxes>

<app-distance-courses></app-distance-courses>

<app-distance-about></app-distance-about>

<app-funfacts-style-two></app-funfacts-style-two>

<app-distance-popular-courses></app-distance-popular-courses>

<app-feedback></app-feedback>

<div class="advisor-area bg-f9f9f9 pt-100">
    <app-instructor-style-two></app-instructor-style-two>
</div>

<div class="premium-access-area bg-f9f9f9 ptb-100">
    <app-premium-access></app-premium-access>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div>

<div class="partner-area bg-f0f2f5 ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>

<app-distance-blog></app-distance-blog>

<div class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div>