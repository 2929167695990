<div class="view-all-courses-area bg-fef8ef">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-content">
                    <span class="sub-title">INSEGNAMENTO A DISTANZA</span>
                    <h2>Sentiti come se stessi frequentando le tue lezioni fisicamente!</h2>
                    <p>I nostri corsi possono offrirti un'esperienza super emozionante di apprendimento online! Non affronti mai alcuna esperienza negativa mentre ti godi virtualmente le tue lezioni sedendoti nella tua zona di comfort. Le nostre iniziative di apprendimento flessibile ti aiuteranno a imparare meglio e più velocemente rispetto ai metodi tradizionali di apprendimento delle abilità.</p>
                    <a routerLink="/elenco-corsi" class="default-btn"><i class="flaticon-agenda"></i>Visualizza i Corsi<span></span></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-image">
                    <img src="assets/img/man-with-laptop.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
