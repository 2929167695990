import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-about-style-four',
    templateUrl: './about-style-four.component.html',
    styleUrls: ['./about-style-four.component.scss']
})
export class AboutStyleFourComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}