import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learning-management',
  templateUrl: './learning-management.component.html',
  styleUrls: ['./learning-management.component.scss']
})
export class LearningManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
