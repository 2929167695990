<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our New</span>
            <h2 class="playfair-display-font">Check Out Our Latest Blog</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="assets/img/yoga-blog-img1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Yoga</a>
                        <h3 class="playfair-display-font"><a routerLink="/single-blog-1">Surprising SUP Yoga Poses You’ll Want to Try This Summer</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    <span>Alex Morgan</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 30, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="assets/img/yoga-blog-img2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">YTT</a>
                        <h3 class="playfair-display-font"><a routerLink="/single-blog-1">7 Things I Learned From Doing One of Those Social Media Yoga</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 29, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="assets/img/yoga-blog-img3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Yoga</a>
                        <h3 class="playfair-display-font"><a routerLink="/single-blog-1">10 Ways to Get Real About Your Body’s Limitations & Avoid</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                    <span>David Warner</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 28, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>