import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learning-premium-access',
  templateUrl: './learning-premium-access.component.html',
  styleUrls: ['./learning-premium-access.component.scss']
})
export class LearningPremiumAccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
