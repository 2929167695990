import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    @ViewChild('username') username: ElementRef;
    @ViewChild('password') password: ElementRef;

    public homeBannerSlider: boolean=false;


  constructor(
    private crudService: CrudService,
  ) { }

  ngOnInit(): void {
    //this.loadSettings();
  }

  loadSettings() {
    this.crudService.api_get_SettingsValue("home-banner-slider").subscribe(productData => {
        this.homeBannerSlider=productData==1 ? true : false;
    },(err)=>{
        //console.log(err);
        }, ()=>{
    });
}

errorReset() {
    Swal.fire({
        icon: 'error',
        title: 'Reset Password',
        text: 'compila i campi per procedere al reset della password.',
        showCancelButton: false,
        confirmButtonColor: 'var(--mainColor)',
        confirmButtonText: 'Chiudi',
        width: '400px',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
        }
      })
}

  resetPassword() {
    let emailReset=(document.getElementById('emailReset')  as HTMLInputElement).value;
    let username=(document.getElementById('usernameReset')  as HTMLInputElement).value;

    if(!emailReset || !username) {
        this.errorReset();
        return;
    }

    Swal.fire({
        icon: 'info',
        title: 'Sei sicuro?',
        text: 'vuoi procedere al reset della password?',
        showCancelButton: true,
        confirmButtonColor: 'var(--mainColor)',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Si, confermo!',
        cancelButtonText: 'Annulla',
        width: '400px',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
            let emailReset=(document.getElementById('emailReset')  as HTMLInputElement).value;
            let username=(document.getElementById('usernameReset')  as HTMLInputElement).value;

            if(emailReset) {
                this.crudService.api_modificaPasswordEmail(username, emailReset).subscribe(
                    productData => {
                        console.log(productData);

                        if(productData!=0) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Reset Password',
                                text: 'password resettata con successo. La nuova password è "'+username+'", per modificare la password, accedi al tuo profilo e modifica la password.',
                                showCancelButton: false,
                                confirmButtonColor: '#6993FF',
                                confirmButtonText: 'Chiudi',
                                width: '400px',
                                allowOutsideClick: false,
                              }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.reload();
                                }
                              })
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Errore!',
                                text: 'non riesco a cambiare la password, contatta l\'assistenza.',
                                showCancelButton: false,
                                confirmButtonColor: 'var(--mainColor)',
                                confirmButtonText: 'Riprova',
                                width: '400px',
                                allowOutsideClick: false,
                              }).then((result) => {
                                if (result.isConfirmed) {
                                }
                              })
                        }
                    },(err)=>{
                        console.log(err);
                        }, ()=>{
                    }
                );
            }
            else  {
                Swal.fire({
                    icon: 'error',
                    title: 'Errore!',
                    text: 'compila i campi USERNAME e E-MAIL.',
                    showCancelButton: false,
                    confirmButtonColor: '#6993FF',
                    confirmButtonText: 'Riprova',
                    width: '400px',
                    allowOutsideClick: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                    }
                  })
            }
        }
    })
  }

  checkLoginSmall(){
    let usernameForm=this.username.nativeElement.value;
    let passwordForm=this.password.nativeElement.value;

    this.crudService.api_Login(usernameForm, passwordForm).subscribe(responseData => {
        //console.log(responseData);
        if(responseData['status']) {
            localStorage.setItem("user_id",JSON.stringify(responseData));
            document.getElementById('btnLogin').style.backgroundColor="#000";

            Swal.fire({
                title:'Account Verificato!',
                text: 'login effettuato con successo, accedi ai tuoi corsi.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: 'var(--mainColor)',
                cancelButtonColor: '#dadada',
                confirmButtonText: 'Iniziamo',
                allowOutsideClick: false,
             }).then((result) => {
                if (result.isConfirmed) {
                    location.href="/elenco-corsi/i-miei-corsi";
                }
            });
        }
        else {
            Swal.fire({
                title:'Login!',
                text: 'non abbiamo nessun account abilitato con i dati inseriti, riprova',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: 'var(--mainColor)',
                cancelButtonColor: '#dadada',
                confirmButtonText: 'Ok, riprovo',
                allowOutsideClick: false,
             }).then((result) => {
                if (result.isConfirmed) {

                }
            });
        }
    });
}

}
